import {Injectable} from '@angular/core';
import {EmailData, GetEmail, GetEmailResponse, SendEmail, SendEmailResponse} from '../data/emails';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {fromFetch} from 'rxjs/fetch';
import {map, switchMap} from 'rxjs/operators';


@Injectable()
export class EmailService extends EmailData {
  getEmails(token: string): Observable<GetEmailResponse> {
    const url = environment.baseUrl;
    const headers = new Headers({ 'Content-Type': 'application/json', 'x-token': `${token}` });
    const method = 'GET';
    const request = new Request(`${url}/api/allEmails`, { headers, method});
    return fromFetch(request).pipe(
      switchMap(response => {
        return response.json();
      }),
    );
  }

  sendEmail(token: string,  sendEmail: FormData): Observable<SendEmailResponse> {
    const url = environment.baseUrl;
    const headers = new Headers({ 'x-token': `${token}` });
    const method = 'POST';
    const request = new Request(`${url}/api/sendEmail`,
      { headers: headers, method: method, body: sendEmail });
    return fromFetch(request).pipe(
      switchMap((response: Response) => {
        return response.json();
      }),
    );
  }
}
