<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Emailer<span>UI</span></a>
  </div>
<!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
<!--  </nb-select>-->
</div>

<div class="header-container">
  <button nbButton ghost (click)="logout()"><nb-icon icon="power-outline" pack="eva"></nb-icon></button>
  <nb-actions size="small">
<!--    <nb-action class="control-item" icon="email-outline"></nb-action>-->
<!--    <nb-action class="control-item" icon="bell-outline"></nb-action>-->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user
               [onlyPicture]="userPictureOnly"
               [name]="user_inst?.user?.fullName"
               [picture]="null">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
