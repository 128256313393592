import {Observable} from 'rxjs';
import {Form} from "@angular/forms";

export interface GetEmailResponse {
  code: number;
  data: GetEmail[];
  success: boolean;
}

export interface GetEmail {
  name: string;
  picture: string;
}

export interface SendEmailResponse{
  success: boolean;
  data: {};
  code: number;
}

export interface SendEmail {
  To: string;
  Subject: string;
  Body: string;
  CC: string;
  BCC: string[] | string;
}

export abstract class EmailData {
  abstract getEmails(token: string): Observable<GetEmailResponse>;
  abstract sendEmail(token: string, sendEmail: FormData): Observable<SendEmailResponse>;
}
